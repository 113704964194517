import React, { Component } from 'react';
import logo from './logo.svg';


import {
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Image,
    List,
    Menu,
    Segment,
    Item,
    Icon,
    Button
} from 'semantic-ui-react'
import ImageGallery from 'react-image-gallery';
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
import './App.css';
import WelcomePage from './WelcomePage.js';
import ThreeBedPage from './ThreeBedPage.js';
import TwoBedPage from './TwoBedPage.js';
import GateHouse from './GateHouse.js';
import Amenities from './Amenities.js';
import WhatsOn from './WhatsOn.js';
import Contact from './Contact.js';

class App extends Component {


  constructor(props, context) {
    super(props, context);

    this.state = {
      showWelcomePage: true,
      showThreeBedPage: false,
      showTwoBedPage: false,
      showGateHouse: false,
      showAmenities: false,
      showWhatsOn: false,
      showContact: false
    };


  }

  onContact() {
    this.setState({showWelcomePage : false, showThreeBedPage : false, showTwoBedPage: false,  showGateHouse: false,  showAmenities: false, showWhatsOn: false, showContact: true});
  }
  onWhatsOn() {
    this.setState({showWelcomePage : false, showThreeBedPage : false, showTwoBedPage: false,  showGateHouse: false,  showAmenities: false, showWhatsOn: true, showContact: false});
  }

  onAmenities() {
    this.setState({showWelcomePage : false, showThreeBedPage : false, showTwoBedPage: false,  showGateHouse: false,  showAmenities: true, showWhatsOn: false,showContact: false});
  }

  onThreeBed() {
    this.setState({showWelcomePage : false, showThreeBedPage : true, showTwoBedPage: false,  showGateHouse: false, showAmenities: false , showWhatsOn: false,showContact: false});
  }

  onTwoBed() {
    this.setState({showWelcomePage : false, showThreeBedPage : false, showTwoBedPage: true, showGateHouse: false,  showAmenities: false, showWhatsOn: false,showContact: false});
  }
  onGateHouse() {
    this.setState({showWelcomePage : false, showThreeBedPage : false, showTwoBedPage: false, showGateHouse: true,  showAmenities: false, showWhatsOn: false,showContact: false});
  }
  onWelcomePage() {
    console.log(this.state);
    this.setState({showWelcomePage : true, showThreeBedPage : false,  showTwoBedPage: false, showGateHouse: false,  showAmenities: false, showWhatsOn: false,showContact: false});
  }



  render() {
    const {showWelcomePage, showThreeBedPage, showTwoBedPage, showGateHouse,  showAmenities, showWhatsOn, showContact} = this.state;
    return (
        <div>
          <Menu fixed='top' inverted style={{ backgroundColor: '#a8c5bb' }}>
            <Container>
              <Menu.Item as='a' header  onClick={()=>this.onWelcomePage()} >
                <Image size='mini' src='/bt_logo_small.png' style={{ height: '60px', width: '110px', marginRight: '1.5em' }} />
                Bay Tree Cottages Holiday Homes Norfolk
              </Menu.Item>
              <Menu.Item as='a'  onClick={()=>this.onWelcomePage()}>Home</Menu.Item>

              <Dropdown item simple text='Cottages'>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={()=>this.onThreeBed()} >Bay Tree Cottages (3 Bed)</Dropdown.Item>
                  <Dropdown.Item onClick={()=>this.onTwoBed()}>Bay Tree Cottages (2 Bed)</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>Coming Soon</Dropdown.Header>

                  <Dropdown.Item onClick={()=>this.onGateHouse()}>The Gate House</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Menu.Item as='a' onClick={()=>this.onAmenities()}>Local Amenities</Menu.Item>
              <Menu.Item as='a' onClick={()=>this.onWhatsOn()}>Things to do</Menu.Item>
              <Menu.Item as='a' onClick={()=>this.onContact()}>Book / Contact</Menu.Item>
            </Container>
          </Menu>


          { showWelcomePage &&  <WelcomePage/>}
          { showThreeBedPage &&  <ThreeBedPage/>}
          { showTwoBedPage &&  <TwoBedPage/>}
          { showGateHouse &&  <GateHouse/>}
          { showAmenities &&  <Amenities/>}
          { showWhatsOn &&  <WhatsOn/>}
          { showContact &&  <Contact/>}

          <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em', backgroundColor: '#a8c5bb'  }}>
            <Container textAlign='center'>
              <Grid divided inverted stackable>
                <Grid.Column width={3}>
                  <Header inverted as='h4' content='3 Bed - Bay Tree Cottages'  onClick={()=>this.onThreeBed()}/>
                  <List link inverted>
                    <List.Item as='a'  onClick={()=>this.onThreeBed()}>Converted 10 years ago and just recently re-energised in 2019.</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header inverted as='h4' content='2 Bed - Bay Tree Cottages'  onClick={()=>this.onTwoBed()}/>
                  <List link inverted>
                    <List.Item as='a'  onClick={()=>this.onTwoBed()}>Traditional exposed beams and the magic of the history where these buildings have been sitting for hundreds of years.   </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header inverted as='h4' content='The Gate House'  onClick={()=>this.onGateHouse()}/>
                  <List link inverted>
                    <List.Item as='a'  onClick={()=>this.onGateHouse()}>Coming Soon....</List.Item>

                  </List>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Header inverted as='h4' content='Follow Us' />
                  <Container textAlign='center'>
                      <Grid centered stackable>
                        <Grid.Column centered width={3}>
                          <a href="https://www.facebook.com/baytreecottagesnorfolk"> <Image centered src='/images/f-ogo_RGB_HEX-100.png' style={{ height: '37px', width: '40px' }} /></a>
                        </Grid.Column>
                        <Grid.Column centered width={3}>
                          <a href="https://www.instagram.com/baytreecottagesnorfolk/"> <Image centered src='/images/instagram-logo.png'  style={{ height: '37px', width: '40px' }}/></a>
                        </Grid.Column>
                      </Grid>
                  </Container>
                </Grid.Column>
              </Grid>



              <Divider inverted section />
              <Image centered size='mini' src='/bt_logo_small.png' />
              <List horizontal inverted divided link size='small'>

                <List.Item as='a' href='#'  onClick={()=>this.onContact()}>
                  Contact Us
                </List.Item>

              </List>
            </Container>
          </Segment>
        </div>
    );
  }
}

export default App;
