import React, { Component } from 'react';
import {
	Container,
	Divider,
	Dropdown,
	Grid,
	Header,
	Image,
	List,
	Menu,
	Segment,
	Item,
	Icon,
	Button
} from 'semantic-ui-react'
import ImageGallery from 'react-image-gallery';
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
import './App.css';

class GateHouse extends Component
{
	constructor(props){
		super(props);
		this.state = {};
	}





	componentDidMount(){

	};


	render() {
		//console.log("It is Android: " + this.state.adUnitID);

		const images = [

			{
				original: '../images/GateHouse.jpg',

			}

		]

		return (

				<div>
					<Container style={{ marginTop: '9em' }}>


						<Grid centered stackable>
							<Grid.Row centered>
								<Grid.Column width={13}>
									<div className="listing_title">Gate House - Coming Soon</div>
									<div className="room_tags">
										<span className="room_tag"><a href="#">Coming Soon</a></span>
										<span className="room_tag"><a href="#">2019</a></span>
									</div>
								</Grid.Column>

							</Grid.Row>
							<Grid.Row columns={1}>
								<Grid.Column width={13}>
										<ImageGallery items={images} showThumbnails={false} autoPlay={false} showPlayButton={false}/>
								</Grid.Column>
							</Grid.Row>


						</Grid>
					</Container>
				</div>


		);
	}
};




const styles = {

};




export default GateHouse;
