import React, { Component } from 'react';
import {
	Container,
	Grid,
	Image,
	List,
	Card,
	Icon
} from 'semantic-ui-react'
import './App.css';
import ImageGallery from 'react-image-gallery';
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";

import VideoPlayer from 'react-simple-video-player';

class WhatsOn extends Component
{
	constructor(props){
		super(props);
		this.state = {
		};
	}





	componentDidMount(){

	};


	render() {
		//console.log("It is Android: " + this.state.adUnitID);
		const images = [

			{
				original: '../images/aea-quad-biking.jpg',

			},
			{
				original: '../images/aea-clay-shooting.jpg',
			},
			{
				original: '../images/aea-archery.jpg'
			}

		]



		return (

				<div>
					<Container style={{ marginTop: '9em' }}>


						<Grid centered stackable>
							<Grid.Row centered>
								<Grid.Column width={5}>
									<Card>
										<Image src='/images/diss_golf_club_7.jpg' />
										<Card.Content>
											<Card.Header><a href="http://www.dissgolf.co.uk/" target="_NEW">Diss Golf Club</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>
												early to late
											</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>5 minutes / 2 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>

								</Grid.Column>
								<Grid.Column width={5}>
									<Card>
										<ImageGallery items={images} showThumbnails={false} autoPlay={true} showPlayButton={false}/>
										<Card.Content>
											<Card.Header><a href="http://www.eastanglianactivities.co.uk/" target="_NEW">East Anglian Activities</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>8am - 5pm</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>36 minutes / 20.7 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
								<Grid.Column width={5}>

									<Card>

										<VideoPlayer
											url="../images/banham.mp4"
											poster="../images/banham_logo.png"

											autosize
											autoplay
											/>
									<Card.Content>
										<Card.Header><a href="http://www.banhamzoo.co.uk/" target="_NEW">Banham Zoo</a></Card.Header>
										<Card.Meta>
											<span className='date'>Opening Times</span>
										</Card.Meta>
										<Card.Description>9:30am–5pm</Card.Description>

									</Card.Content>
									<Card.Content extra>
										<a>
											<a>
												<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
												<span className='date'>15 minutes / 7.4 mi Miles</span>
											</a>
										</a>
									</Card.Content>
								</Card>
								</Grid.Column>
							</Grid.Row>

							<Grid.Row centered>

								<Grid.Column width={5}>

									<Card>
										<Image src='/images/norwich-castle.jpg' />
										<Card.Content>
											<Card.Header><a href="https://www.museums.norfolk.gov.uk/norwich-castle" target="_NEW">Norwich Castle</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>Monday to Saturday 10am-4.30pm<br/>
												Sundays 1-4.30pm</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>40 minutes / 24 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
								<Grid.Column width={5}>
									<Card>
										<Image src='/images/swim.jpg' />
										<Card.Content>
											<Card.Header><a href="https://www.south-norfolk.gov.uk/visitors/leisure-centres/diss-leisure-centre" target="_NEW">Diss Swimming</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>Monday to Friday: 06:30 to 22:00<br/>
												Saturday and Sunday: 08:30 to 18:00<br/>
												Bank holidays: 10:00 to 18:00
											</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>5 minutes / 2.4 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
								<Grid.Column width={5}>
									<Card>
										<Image src='/images/ghost.jpg' />
										<Card.Content>
											<Card.Header><a href="https://www.adnams.co.uk/" target="_NEW">Adams Tour - Southwold</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>
												09:00 to 17:30
											</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>45 minutes / 24 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>

							</Grid.Row>

							<Grid.Row centered>

								<Grid.Column width={5}>

									<Card>
										<VideoPlayer
											url="https://youtu.be/_qWfZgIUxW4"
											poster="../images/monsters_logo.png"
											autosize
											autoplay
											/>
										<Card.Content>
											<Card.Header><a href="https://www.monstersltd.co.uk/" target="_NEW">Monsters</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>9:30am–6pm</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>5 minutes / 2 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
								<Grid.Column width={5}>
									<Card>
										<Image src='/images/roar.jpg' />
										<Card.Content>
											<Card.Header><a href="https://www.roarrdinosauradventure.co.uk/" target="_NEW">Dinosaur Adventure</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>9:30am–4pm
											</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>50 minutes / 32 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
								<Grid.Column width={5}>
									<Card>
										<Image src='/images/boat-hire-on-the-broads.png' />
										<Card.Content>
											<Card.Header><a href="https://www.norfolkbroads.com" target="_NEW">Norfolk Broads</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>
												08:00 to 17:30
											</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>30 minutes / 25 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>

							</Grid.Row>

							<Grid.Row centered>

								<Grid.Column width={5}>

									<Card>
										<Image src='/images/shawsgate-stamp.jpg' />
										<Card.Content>
											<Card.Header><a href="https://www.shawsgate.co.uk" target="_NEW">Shawsgate Wine Tasting</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>10:00am–5pm</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>29 minutes / 16 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
								<Grid.Column width={5}>
									<Card>
										<Image src='/images/framlingham-knights-tournament.jpg' />
										<Card.Content>
											<Card.Header><a href="https://www.english-heritage.org.uk/visit/places/framlingham-castle/" target="_NEW">FRAMLINGHAM CASTLE</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>10:00am–6pm</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>29 minutes / 16 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
								<Grid.Column width={5}>
									<Card>
										<Image src='/images/garden-line-reduced.jpg' />
										<Card.Content>
											<Card.Header><a href="https://www.bressingham.co.uk" target="_NEW">Bressingham Steam & Gardens</a></Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>10:30 - 5pm</Card.Description>

										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Icon name='car' style={{ color: '#a8c5bb'}} size='large'/>
													<span className='date'>12 minutes / 4.3 Miles</span>
												</a>
											</a>
										</Card.Content>
									</Card>

								</Grid.Column>

							</Grid.Row>


						</Grid>
					</Container>
				</div>


		);
	}
};




const styles = {

};




export default WhatsOn;
