import React, { Component } from 'react';
import {
	Container,
	Divider,
	Dropdown,
	Grid,
	Header,
	Image,
	List,
	Menu,
	Segment,
	Item,
	Icon,
	Button
} from 'semantic-ui-react'
import ImageGallery from 'react-image-gallery';
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
import './App.css';

class Contact extends Component
{
	constructor(props){
		super(props);
		this.state = {};
	}





	componentDidMount(){

	};


	render() {
		//console.log("It is Android: " + this.state.adUnitID);


		return (

				<div>
					<Container style={{ marginTop: '9em' }}>
						<Grid stackable centered>
							<Grid.Row columns={1}>
								<Grid.Column width={10}>
									<Header as='h1' style={{  color: 'grey', fontSize: '22px' , fontWeight: 'normal',  fontFamily: 'Myriad', marginTop: '-10px', marginBottom : '10px' }}>
										Contact or Request Booking
									</Header>
									<Item.Content verticalAlign='middle'>
										<p> Please send us an email to: <a href={"mailto:info@baytreecottagesnorfolk.com"}> info@baytreecottagesnorfolk.com</a> with the following information</p>

										<List>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Which Cottage you would like to book
													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Who is in your party? Number of adults / children and their ages please.

													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Dates you would like to arrive and depart

													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														A phone number and the location you are traveling from.
													</List.Header>
												</List.Content>
											</List.Item>

										</List>


									</Item.Content>

								</Grid.Column>
							</Grid.Row>

						</Grid>
					</Container>
				</div>


		);
	}
};




const styles = {

};




export default Contact;
