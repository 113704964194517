import React, { Component } from 'react';
import {
	Container,
	Divider,
	Dropdown,
	Grid,
	Header,
	Image,
	List,
	Menu,
	Segment,
	Item,
	Icon,
	Button
} from 'semantic-ui-react'
import ImageGallery from 'react-image-gallery';
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
import './App.css';

class WelcomePage extends Component
{
	constructor(props){
		super(props);
		this.state = {};
	}





	componentDidMount(){

	};


	render() {
		//console.log("It is Android: " + this.state.adUnitID);
		const {handleSubmit, pristine, reset, submitting} = this.props;
		const images = [

			{
				original: '../images/main.jpg'
			},
			{
				original: '../images/3FV.jpg'
			},
			{
				original: '../images/3BMF.jpg'
			},
			{
				original: '../images/3BMF2.jpg'
			},
			{
				original: '../images/3BMFK.jpg'
			},
			{
				original: '../images/3BMFK1.jpg'
			},
			{
				original: '../images/3BMB.jpg'
			},
			{
				original: '../images/3BMB2.jpg'
			},
			{
				original: '../images/3BMB3.jpg'
			},
			{
				original: '../images/3BMBath.jpg'
			},
			{
				original: '../images/2FVCrop.jpg'
			},
			{
				original: '../images/2BMF.jpg'
			},
			{
				original: '../images/2BMK.jpg'
			},
			{
				original: '../images/2BMF2.jpg'
			},
			{
				original: '../images/2BMBath.jpg'
			},
			{
				original: '../images/chill.jpg'
			},
			{
				original: '../images/2BMB.jpg'
			},
			{
				original: '../images/2BMB2.jpg'
			},
			{
				original: '../images/2BMB2Tv.jpg'
			},
			{
				original: '../images/parking.jpg'
			},
			{
				original: '../images/garden1.jpg'
			},
			{
				original: '../images/garden2.jpg'
			},
			{
				original: '../images/gate.jpg'
			},
			{
				original: '../images/last.jpg'
			}
		]

		return (

				<div>
					<Container style={{ marginTop: '9em' }}>
						<Grid stackable>
							<Grid.Row columns={2}>
								<Grid.Column width={10}>
									<ImageGallery items={images} showThumbnails={false} autoPlay={true} showPlayButton={false}/>
								</Grid.Column>
								<Grid.Column width={6}>
									<Header as='h1' style={{  color: 'grey', fontSize: '28px' , fontWeight: 'bold', textTransform: 'uppercase', fontFamily: 'Myriad', marginTop: '-10px', marginBottom : '10px' }}>
										Bay Tree Cottages
									</Header>
									<Item.Content verticalAlign='middle'>
										<p>
											Bay Tree Cottages are a pair of beautiful flint and timber framed barns, which are located on the border of Norfolk and Suffolk. They are situated in the pretty village of Scole, a well served village with a shop, hotel with bar and restaurant and traditional pub, all accessible in a 1 minute walk.
										</p><p>
										The cottages are securely located behind large security gates and sit on the Bay Tree Estate where you can enjoy a private and relaxing holiday
									</p><p>
										There are two cottages available, a 3 bedroom and a 2 bedroom, which are fully furnished and provide luxury for restful days. The local golf course and swimming pool are 2 miles away in the main market town of Diss. Diss also has a train station with easy access to trains direct to Norwich (17 minutes) and London (80 minutes)
									</p><p>
										Bay Tree Holiday Cottages are attractively located on the border of Norfolk and Suffolk, offering an excellent base to explore the wonderful tourist attractions, golf courses, beaches, pubs and restaurants that both counties have to offer.
									</p>
										<p> <Icon name='mail' style={{ color: '#a8c5bb' }} size='large' /> <a href={"mailto:info@baytreecottagesnorfolk.com"}> info@baytreecottagesnorfolk.com</a></p>

									</Item.Content>

								</Grid.Column>
							</Grid.Row>

						</Grid>
					</Container>
				</div>


		);
	}
};




const styles = {

};




export default WelcomePage;
