import React, { Component } from 'react';
import {
	Container,
	Divider,
	Dropdown,
	Grid,
	Header,
	Image,
	List,
	Menu,
	Segment,
	Item,
	Icon,
	Button
} from 'semantic-ui-react'
import ImageGallery from 'react-image-gallery';
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
import './App.css';

class ThreeBedPage extends Component
{
	constructor(props){
		super(props);
		this.state = {};
	}





	componentDidMount(){

	};


	render() {
		//console.log("It is Android: " + this.state.adUnitID);

		const images = [

			{
				original: '../images/2FVCrop.jpg',
				thumbnail: '../images/2FVCrop.jpg'
			},
			{
				original: '../images/2BMF2.jpg',
				thumbnail: '../images/2BMF2.jpg'
			},
			{
				original: '../images/2BMF.jpg',
				thumbnail: '../images/2BMF.jpg'
			},

			{
				original: '../images/2BMK.jpg',
				thumbnail: '../images/2BMK.jpg'
			},
			{
				original: '../images/2BMB.jpg',
				thumbnail: '../images/2BMB.jpg'
			},
			{
				original: '../images/2BMB2.jpg',
				thumbnail: '../images/2BMB.jpg'
			},
			{
				original: '../images/2BMB2Tv.jpg',
				thumbnail: '../images/2BMB2Tv.jpg'
			},
			{
				original: '../images/2BMBath.jpg',
				thumbnail: '../images/2BMBath.jpg'
			},
			{
				original: '../images/chill.jpg',
				thumbnail: '../images/chill.jpg'
			},
			{
				original: '../images/parking.jpg',
				thumbnail: '../images/parking.jpg'
			},
			{
				original: '../images/main.jpg',
				thumbnail: '../images/main.jpg'
			},
			{
				original: '../images/garden1.jpg',
				thumbnail: '../images/garden1.jpg'
			},
			{
				original: '../images/garden2.jpg',
				thumbnail: '../images/garden2.jpg'
			},
			{
				original: '../images/gate.jpg',
				thumbnail: '../images/gate.jpg'
			},
			{
				original: '../images/last.jpg',
				thumbnail: '../images/last.jpg'
			}
		]

		return (

				<div>
					<Container style={{ marginTop: '9em' }}>


						<Grid centered stackable>
							<Grid.Row centered>
								<Grid.Column width={13}>
									<div className="listing_title">2 Bed Barn in Scole</div>
									<div className="room_tags">
										<span className="room_tag"><a href="#">Luxury</a></span>
										<span className="room_tag"><a href="#">Private</a></span>
										<span className="room_tag"><a href="#">Relaxing</a></span>
										<span className="room_tag"><a href="#">Great Location</a></span>

									</div>
								</Grid.Column>

							</Grid.Row>
							<Grid.Row columns={1}>
								<Grid.Column width={13}>
										<ImageGallery items={images} showThumbnails={true} autoPlay={false} showPlayButton={false}/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={13}>

									<Item.Content verticalAlign='middle'>
										<div class="room">
											<span class="room_title">Bedrooms</span>
											<div class="room_content">
												<div class="room_image"><Image src="images/bedroom.png" /></div>
													<span class="room_number">2</span>
												</div>
											</div>

											<div class="room">
												<span class="room_title">Bathrooms</span>
												<div class="room_content">
													<div class="room_image"><Image src="images/shower.png"/></div>
														<span class="room_number">1</span>
													</div>
												</div>

												<div class="room">
													<span class="room_title">Kitchen / Diner</span>
													<div class="room_content">
														<div class="room_image"><Image src="images/area.png"/></div>
															<span class="room_number">1</span>
														</div>
													</div>

													<div class="room">
														<span class="room_title">Patio Furniture</span>
														<div class="room_content">
															<div class="room_image"><Image src="images/patio.png"/></div>
																<span class="room_number">1</span>
															</div>
														</div>

														<div class="room">
															<span class="room_title">Parking</span>
															<div class="room_content">
																<div class="room_image"><Image src="images/garage.png"/></div>
																	<span class="room_number">1</span>
																</div>
															</div>
										</Item.Content>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={7}>
									<Item.Content verticalAlign='middle'>
										<Header as='h2' style={{  color: '#52635d', fontSize: '24px' , fontWeight: 'bold',  fontFamily: 'Myriad', marginTop: '-10px', marginBottom : '10px' }}>
											Property
										</Header>

										<List>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Garden furniture
													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														BBQ

													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Heating

													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Gated Premises
													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Parking Space x1 - Extra can be provided upon request

													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Garden
													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Children allowed
													</List.Header>
												</List.Content>
											</List.Item>


										</List>





									</Item.Content>


								</Grid.Column>

								<Grid.Column width={6}>
									<Item.Content verticalAlign='middle'>
										<Header as='h2' style={{  color: '#52635d', fontSize: '24px' , fontWeight: 'bold',  fontFamily: 'Myriad', marginTop: '-10px', marginBottom : '10px' }}>
											Equipment
										</Header>

										<List>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Washing machine
													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Fridge & Freezer
													</List.Header>
												</List.Content>
											</List.Item>

											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Microwave
													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Freeview
													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														Netflix & Google Play - Subscription not included
													</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>Hair Dryer</List.Header>
												</List.Content>
											</List.Item>
											<br/>
											<List.Item>
												<List.Icon name='marker' />
												<List.Content>
													<List.Header style={{ color: '#5fb396' }}>
														WI-FI
													</List.Header>
												</List.Content>
											</List.Item>


										</List>





									</Item.Content>


								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={13}>
								<Item.Content verticalAlign='middle'>
									<Header as='h2' style={{  color: '#52635d', fontSize: '24px' , fontWeight: 'bold',  fontFamily: 'Myriad', marginTop: '-10px', marginBottom : '10px' }}>
										Additional Details
									</Header>

									<List>
									<List.Item>
										<List.Icon name='marker' />
										<List.Content>
											<List.Header style={{ color: '#5fb396' }}>
												Changeover occurs each Saturday
											</List.Header>
										</List.Content>
									</List.Item>
<br/>
									<List.Item>
										<List.Icon name='marker' />
										<List.Content>
											<List.Header style={{ color: '#5fb396' }}>
												Shorter stays available (minimum 2 nights) except in school holidays and high season
											</List.Header>
										</List.Content>
									</List.Item>
										<br/>
									<List.Item>
										<List.Icon name='marker' />
										<List.Content>
											<List.Header style={{ color: '#5fb396' }}>
												Booking deposit 25% at time of booking – balance due 6 weeks before arrival
											</List.Header>
										</List.Content>
									</List.Item>
										<br/>
									<List.Item>
										<List.Icon name='marker' />
										<List.Content>
											<List.Header style={{ color: '#5fb396' }}>
												£350 returnable deposit for damages and breakages
											</List.Header>
										</List.Content>
									</List.Item>
										<br/>
									<List.Item>
										<List.Icon name='marker' />
										<List.Content>
											<List.Header style={{ color: '#5fb396' }}>
												All linen and towels are provided (not beach towels)
											</List.Header>
										</List.Content>
									</List.Item>
										<br/>
									<List.Item>
										<List.Icon name='marker' />
										<List.Content>
											<List.Header style={{ color: '#5fb396' }}>
												Sorry no pets
											</List.Header>
										</List.Content>
									</List.Item>
										<br/>
									<List.Item>
										<List.Icon name='marker' />
										<List.Content>
											<List.Header style={{ color: '#5fb396' }}>No smoking</List.Header>
										</List.Content>
									</List.Item>

								</List>





								</Item.Content>


							</Grid.Column>
						</Grid.Row>

						</Grid>
					</Container>
				</div>


		);
	}
};




const styles = {

};




export default ThreeBedPage;
