import React, { Component } from 'react';
import {
	Container,
	Grid,
	Image,
	List,
	Card,
	Icon
} from 'semantic-ui-react'
import './App.css';

class Amenities extends Component
{
	constructor(props){
		super(props);
		this.state = {};
	}





	componentDidMount(){

	};


	render() {
		//console.log("It is Android: " + this.state.adUnitID);



		return (

				<div>
					<Container style={{ marginTop: '9em' }}>


						<Grid centered stackable>
							<Grid.Row centered>
								<Grid.Column width={5}>
									<Card>
										<Image src='/images/ScoleStore.jpg' />
										<Card.Content>
											<Card.Header>Scole Village Store</Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>6am - 7pm</Card.Description>
										</Card.Content>
										<Card.Content extra>
											<a>
												<a>
													<Image src='/images/man_walking.png' height="30px" width="30px" style={{ marginLeft: '-9px' }}/>
													<span className='date'>30 seconds walk</span>
												</a>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
								<Grid.Column width={5}>
									<Card>
										<Image src='/images/crossway.jpg' />
										<Card.Content>
											<Card.Header>The Crossways Inn Pub</Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>Midday – 1am</Card.Description>
										</Card.Content>
										<Card.Content extra>
											<a>
												<Image src='/images/man_walking.png' height="30px" width="30px" style={{ marginLeft: '-9px' }}/>
												<span className='date'>1 minute walk</span>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
								<Grid.Column width={5}>

									<Card>
										<Image src='/images/mcd.jpg' />
										<Card.Content>
											<Card.Header>McDonald's</Card.Header>
											<Card.Meta>
												<span className='date'>Coming Soon... </span>
											</Card.Meta>
											<Card.Description>Spring 2019</Card.Description>
										</Card.Content>
										<Card.Content extra>
											<a>
												<Image src='/images/man_walking.png' height="30px" width="30px" style={{ marginLeft: '-9px' }}/>
												<span className='date'>5 minute walk</span>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
							</Grid.Row>

							<Grid.Row centered>

								<Grid.Column width={5}>

									<Card>
										<Image src='/images/scoleInn.jpg' />
										<Card.Content>
											<Card.Header>The Scole Inn Restaurant</Card.Header>
											<Card.Meta>
												<span className='date'>Opening Times</span>
											</Card.Meta>
											<Card.Description>7am–12am</Card.Description>
										</Card.Content>
										<Card.Content extra>
											<a>
												<Image src='/images/man_walking.png' height="30px" width="30px" style={{ marginLeft: '-9px' }}/>
												<span className='date'>1 minute walk</span>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>
								<Grid.Column width={5}>
									<Card>
										<Image src='/images/MS-Simply-Food-Pic.jpg' />
										<Card.Content>
											<Card.Header>M&S Simply Food </Card.Header>
											<Card.Meta>
												<span className='date'>Coming Soon... </span>
											</Card.Meta>
											<Card.Description>Spring 2019</Card.Description>
										</Card.Content>
										<Card.Content extra>
											<a>
												<Image src='/images/man_walking.png' height="30px" width="30px" style={{ marginLeft: '-9px' }}/>
												<span className='date'>5 minute walk</span>
											</a>
										</Card.Content>
									</Card>
								</Grid.Column>

							</Grid.Row>

						</Grid>
					</Container>
				</div>


		);
	}
};




const styles = {

};




export default Amenities;
